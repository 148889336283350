import React from "react"
import "./index.scss"
import {
  isMobile
} from "react-device-detect";

class WistiaVideo extends React.Component {
  _isMounted = false;
  constructor () {
    super()
    this.state = {
      currentVideo: false,
      playerLoaded: false,
      isMobile: false,
    }
    this.onVideoReady = this.onVideoReady.bind(this);
    this.initTracking = this.initTracking.bind(this);
    this.beforeVideoReplace = this.beforeVideoReplace.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    const wistiaLib = document.createElement("script");
    wistiaLib.src = `https://fast.wistia.net/assets/external/E-v1.js`;
    wistiaLib.async = true; 
    document.body.appendChild(wistiaLib);
    this.initTracking();
    // console.log(`yo yo this is mobile ${isMobile}`);
    this.setState({
      isMobile: isMobile
    });
  }

  onVideoReady(video){
    // console.log(`WISTIA VIDEO IS READY `, video.name());
    // console.log(`component is mounted? ${this._isMounted}`)
    if (this._isMounted){
      this.setState({
        currentVideo: video
      });
    }
    video.bind('beforereplace', this.beforeVideoReplace)
  }

  componentWillUpdate(nextProps) {
    // console.log('should video CHANGE? , video id = ', nextProps);
    // console.log(`CURRENT VIDEO ID `, this.props.wistiaID)
    // console.log(`do we have a current video ${this.state.currentVideo}`)
    if (nextProps.wistiaID !== this.props.wistiaID && typeof this.state.currentVideo === "object" ) {
        // console.log('video should change')
        // console.log(`UPDATED VIDEO IS ${nextProps.wistiaID}`)
        this.replaceWith(this.props.wistiaID,nextProps.wistiaID)
    }
  }

  initTracking(){
    setTimeout( () => {
      window._wq.push({ id: '_all', onReady: this.onVideoReady });
    }, 900);
     // exit if no wistia
    if ( typeof window.Wistia === `undefined`) { return }
    // console.log(`HAVE WISTIA testing for ${this.props.wistiaID}`)
    // console.log(window.Wistia)
    const video = window.Wistia.api(this.props.wistiaID)

    // exit if no vid 
    if ( typeof video === `undefined`) { return }

    // console.log(`video is`)
    // console.log(video.name());
  }

  beforeVideoReplace(video){
    // console.log(`WISTIA BEFORE VIDEO REPLACE`);
    // this.setState({currentVideo: false})

  }

  replaceWith(oldVideoID, nextVideoID){
    
    if ( typeof window.Wistia === `undefined`) { return }
    const video = window.Wistia.api(oldVideoID)

    if ( typeof video === `undefined`) { return }
    // console.log(`REPLACING VIDEO with ${nextVideoID}`);
    // console.log(video.name());
    video.replaceWith(nextVideoID, {transition: 'crossfade', autoPlay: true})
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  render() {
      const { wistiaID } = this.props;
      const width = (this.state.isMobile) ? '95vw': '100%'
      // console.log(`render wistia id is ${wistiaID}`);

      return (
          <div id={`video-container-${wistiaID}`} className='animated' style={{width: width, paddingBottom: '2rem'}}>
              <div className="wistia_responsive_padding" style={{padding: '56.25% 0 0 0', position: 'relative', paddingBottom: '2rem'}}><div className="wistia_responsive_wrapper" style={{height: '100%', left: 0, position: 'absolute', top: 0, width: '100%'}}><div className={`wistia_embed wistia_async_${wistiaID} videoFoam=true`} style={{height: '100%', position: 'relative', width: '100%'}}><div className="wistia_swatch" style={{height: '100%', left: 0, opacity: 0, overflow: 'hidden', position: 'absolute', top: 0, transition: 'opacity 200ms', width: '100%'}}><img src={`https://fast.wistia.com/embed/medias/${wistiaID}/swatch`} style={{filter: 'blur(5px)', height: '100%', objectFit: 'contain', width: '100%'}} alt="" aria-hidden="true" /></div></div></div></div>
          </div>
          );
      };
  }

export default WistiaVideo


